import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import DistanceBetweenGEO from "../components/location/DistanceBetweenGEO"
import { listingsMaxPrice, listingsMinPrice } from "../common/utils"
export const addressString = value => {
  return encodeURIComponent(
    value
      .toLowerCase()
      .replace(/[^a-z0-9 _-]+/gi, "-")
      .replaceAll(" ", "")
  )
}

export const searchResultPagePath = address => {
  const location = addressString(address)
  return `/search/${pageSEOPrefix}${location}`
}
export const DID_NOT_MATCH = `/search/did-not-match`

export const pageSEOPrefix = "boats-for-rent-"

export const CATEGORIES = {
  POWERBOAT: "POWERBOAT",
  PERSONAL_WATERCRAFT: "PERSONAL_WATERCRAFT",
  FISHING: "FISHING",
  SAILBOAT: "SAILBOAT",
  WATER_SPORTS: "WATER_SPORTS",
  EXPERIENCES: "EXPERIENCES",
}

const initialState = {
  searchInput: {
    // address: "Toronto, ON, Canada",
    // urlPath: `/search/${pageSEOPrefix}toronto-on-canada`,
    address: "undefined",
    urlPath: undefined,
    lat: undefined,
    lng: undefined,
    source: undefined,
    updatedOn: undefined,
  },
  items: [],
  itemsToDisplay: [],
  itemsToDisplayPage: 0,
  status: "idle",
  serviceLocations: [],
  serviceLocationsWithGeo: [],
}

export const SearchLocationInputSelect = createAsyncThunk(
  "searching/SearchLocationInputSelect",
  async ({ address }, { rejectWithValue }) => {
    try {
      return geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(data => {
          return data
        })
    } catch (err) {
      console.error("search/SearchLocationInputSelect", err)
      return rejectWithValue(err)
    }
  }
)

const searchSlice = createSlice({
  name: "searching",
  initialState,
  reducers: {
    SearchLocationInputSet(state, action) {
      if (!action?.payload) {
        state.searchInput = {
          address: "",
        }
        state.status = "idle"
      } else if (
        (action?.payload?.source === "GOOGLE_API" ||
          action?.payload?.source === "SEARCH_INPUT" ||
          action?.payload?.source === "LOCATION_DROPDOWN") &&
        state.serviceLocationsWithGeo?.length
      ) {
        //Find near by city with a boat for rent.
        const { lat, lng } = action.payload

        const citiesSortedByDistanceFromUser = state?.serviceLocationsWithGeo
          ?.map(city => {
            return {
              ...city,
              distance: DistanceBetweenGEO(
                city.location.lat,
                city.location.lon,
                lat,
                lng,
                "KM"
              ),
            }
          })
          .sort((a, b) => a.distance - b.distance)

        const closest = citiesSortedByDistanceFromUser[0]

        state.searchInput.lat = lat
        state.searchInput.lng = lng
        state.searchInput.address = closest.location_address
        state.searchInput.urlPath = closest.path
        state.searchInput.source = action.payload.source
        state.searchInput.updatedOn = new Date().toISOString()
      } else if (action?.payload?.lat) {
        const { lat, lng } = action.payload
        state.searchInput.lat = lat
        state.searchInput.lng = lng
      } else {
        state.searchInput.address = action.payload
      }
    },

    SearchResultNextPage(state, action) {
      state.itemsToDisplayPage = state.itemsToDisplayPage + 1
    },
    SearchServiceLocations(state, action) {
      if (!action.payload?.length) return
      state.serviceLocations = action.payload.map(item => item.location_address)
      state.serviceLocationsWithGeo = action.payload
    },
    SearchStatusUpdate(state, action) {
      state.status = action.payload.status
    },
  },
  extraReducers: {
    [SearchLocationInputSelect.fulfilled]: (state, action) => {
      const { lat, lng } = action.payload
      state.searchInput.lat = lat
      state.searchInput.lng = lng

      const { serviceLocations } = current(state)

      if (serviceLocations.includes(action.meta.arg?.address)) {
        state.searchInput.urlPath = searchResultPagePath(
          action.meta.arg.address
        )
      } else {
        state.searchInput.urlPath = DID_NOT_MATCH
      }

      state.searchInput.address = action.meta.arg.address
    },
    [SearchLocationInputSelect.pending]: (state, action) => {},
    [SearchLocationInputSelect.rejected]: (state, action) => {},
  },
})

export const {
  SearchServiceLocations,
  SearchResultNextPage,
  SearchLocationInputSet,
  SearchStatusUpdate,
} = searchSlice.actions

export default searchSlice.reducer
