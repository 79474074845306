/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import "./sentry.config"
import { Provider } from "react-redux"
import { persistor, store } from "./src/app/store"
import Amplify from "aws-amplify"
import awsconfig from "./src/aws-exports"
import { PersistGate } from "redux-persist/integration/react"
import oauthRedirectAwsConfig from "./src/components/oauthRedirectsAwsConfig"
import "./src/scss/main.scss"
import "bootstrap/dist/css/bootstrap.min.css"

//TODO: disable after
// window.LOG_LEVEL = "DEBUG"
const configWithOauthRedirect = oauthRedirectAwsConfig(awsconfig)
Amplify.configure(configWithOauthRedirect)

export const wrapPageElement = ({ element, props }) => {
  //i could send some prop when making a page to tell which
  //store to wrap in
  // if (props.pageContext.redux) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
  // } else {
  //   return element
  // }
}

export const onRouteUpdate = ({ location }) => {
  window.previousPath = location.pathname

  //this is used in listing back button

  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath2 = window.locations[window.locations.length - 2]
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location)

  console.log("Gatsby Browser Current Position", currentPosition, location)

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 0)

  return false
}
