import CurrencyFormatter from "currency-formatter"
import { PremiumCheck } from "../components/booking/BookingPremiumCheck"

export const isBrowser = typeof window !== "undefined"
export const capitalize = s => {
  if (typeof s !== "string") {
    return ""
  }
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function capitalizeSentence(sentence) {
  // Split the sentence into words
  const words = sentence.split(" ")

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  // Join the words back into a sentence
  const capitalizedSentence = capitalizedWords.join(" ")

  return capitalizedSentence
}

export const inputNumberCheck = input => {
  return !isNaN(input) ? Number(input) : ""
}

export const DurationPriceHost = (booking, type) => {
  if (!booking?.departureHost) return
  if (PremiumCheck(booking.departureHost)) {
    return type === "price" ? booking.pricePremium : booking.durationPremium
  } else {
    return type === "price" ? booking.price : booking.duration
  }
}

export const getGroupAmountInfo = (adults, children) => {
  const adultsAmount = parseInt(adults) || 0
  const childrenAmount = parseInt(children) || 0

  const adultsString = `${adultsAmount} ${
    adultsAmount > 1 ? "Adults" : "Adult"
  }`
  const childrenString = childrenAmount
    ? ` + ${childrenAmount} ${childrenAmount > 1 ? "Kids" : "Kid"}`
    : ""

  return adultsString + childrenString
}

export const ListingMinPrice = item => {
  const price = CurrencyFormatter.format(
    [1, 2, 3, 4, 5].reduce((amount, pItem) => {
      const pricePerHour =
        item["price_" + pItem] / item["price_" + pItem + "duration"]
      if (pItem === 1) {
        return pricePerHour
      }
      if (amount > pricePerHour) {
        return pricePerHour
      }
      return amount
    }, 0),
    {
      precision: 0,

      code: item.price_currency,
    }
  )
  return price
}
export const ListingMinPriceNumber = item => {
  const price = [1, 2, 3, 4, 5].reduce((amount, pItem) => {
    const pricePerHour =
      item["price_" + pItem] / item["price_" + pItem + "duration"]
    if (pItem === 1) {
      return pricePerHour
    }
    if (amount > pricePerHour) {
      return pricePerHour
    }
    return amount
  }, 0)
  return price
}

export const ratingStringToNumber = str => Number(str.substr(1, 2))

export const ListingRatingStars = item => {
  const rating =
    item.reviews && item.reviews.items.length
      ? item.reviews.items.reduce(
          (amount, rItem) => amount + ratingStringToNumber(rItem.rating),
          0
        ) / item.reviews.items.length
      : 0
  return Number(rating.toFixed(1))
}

// export const ListingReviewsCount = item => item.reviews.items.length
//
// const convertMinsToHrsMins = mins => {
//   let h = Math.floor(Math.abs(mins) / 60)
//   let m = Math.abs(mins) % 60
//   h = h === 0 ? "±00" : mins > 0 && h < 10 ? "+" + "0" + h : "-" + "0" + h
//   m = m < 10 ? "0" + m : m
//   return `UTC${h}:${m}`
// }

export const numberWithCommas = number => {
  return number.toLocaleString("en-US")
}

export const onlyNumber = text => {
  let formattedText = text.replace(/[^0-9]/g, "")
  return formattedText
}

export const getCoverPhoto = listing => {
  return listing.photos?.items?.filter(photo => photo.listingCover === true)[0]
}

export const getShortLocation = location => {
  const splitLocation = location.split(",")
  const result = splitLocation[0] + ", " + splitLocation[1]
  return result
}

function getDateOfNearestDay(day, date = new Date()) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + ((7 + day - date.getDay()) % 7),
    11,
    0,
    0,
    0
  )
}

// export const dateOfNearestSaturday = getDateOfNearestDay(6)
// export const dateOfNearestSunday = getDateOfNearestDay(7)

export const findMakeFirst = (arr, key) =>
  arr.reduce((acc, element) => {
    if (element[key]) {
      return [element, ...acc]
    }
    return [...acc, element]
  }, [])

export const getBuildListings = data => {
  return data.allSitePage.edges
    .filter(item => item.node.path.indexOf("/listing/") + 1)
    .map(item => item.node.path.split("/")[2])
}

export const listingsMinPrice = items => {
  const prices = []
  items.forEach(listing => {
    prices.push(ListingMinPriceNumber(listing))
  })
  return Math.min(...prices)
}

export const listingsMaxPrice = items => {
  const prices = []
  items.forEach(listing => {
    prices.push(ListingMinPriceNumber(listing))
  })
  return Math.max(...prices)
}

export const isHoliday = date => {
  const d = new Date(date).toLocaleDateString().split("/")
  return [
    "1/1",
    "4/2",
    "4/5",
    "5/24",
    "6/24",
    "7/1",
    "8/2",
    "9/6",
    "10/11",
    "11/11",
    "12/25",
  ].includes(`${d[0]}/${d[1]}`)
}

export const isPremium = date => {
  const dayOfWeek = new Date(date).getDay()
  const result =
    dayOfWeek === 5 || dayOfWeek === 6 || dayOfWeek === 0 || isHoliday(date)
  return result
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const getQueryString = function (field, url) {
  if (typeof window === "undefined") return null
  const href = url || window.location.href
  const reg = new RegExp(`[?&]${field}=([^&#]*)`, "i")
  const string = reg.exec(href)
  return string ? string[1] : null
}

export function updateQueryStringParameter(uri, key, value) {
  // Parse the URI and get the query string
  const url = new URL(uri)
  const queryParams = url.searchParams

  // Set the new value for the query parameter
  queryParams.set(key, value)

  // Build the new URL with the updated query string
  url.search = queryParams.toString()
  const updatedUri = url.toString()

  return updatedUri
}

export const toLowerCase = text => {
  if (!text) return
  const lowerCase = text.toLowerCase()
  return lowerCase[0].toUpperCase() + lowerCase.slice(1)
}

export function cleanDynamoIDsInput(input) {
  return input.replace(/[^a-zA-Z0-9_-]/g, "")
}

export function removeNullKeys(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] === null) {
      delete obj[key]
    }
  })
  return obj
}

export const cameFromPathMatch = pathMatcher => {
  if (typeof window !== "undefined") {
    if (!window.previousPath2) {
      return false
    } else if (window.previousPath2.includes(pathMatcher)) {
      return true
    } else {
      return false
    }
  }
}

export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1)

  // we'll store the parameters here
  var obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0]

    // split our query string into its component parts
    var arr = queryString.split("&")

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split("=")

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0]
      var paramValue = typeof a[1] === "undefined" ? true : a[1]

      // (optional) keep case consistent
      paramName = paramName.toLowerCase()
      if (typeof paramValue === "string") paramValue = paramValue.toLowerCase()

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, "")
        if (!obj[key]) obj[key] = []

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1]
          obj[key][index] = paramValue
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue)
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(paramValue)
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue)
        }
      }
    }
  }

  return obj
}

export function invokeFunctionOnce({ cb, name }) {
  const hasEventInvoked = sessionStorage.getItem(name)

  if (!hasEventInvoked) {
    // Invoke analytics event here
    cb()

    // Set the flag in session storage
    sessionStorage.setItem(name, "true")
  }
}

export function VWOevent(name) {
  // Add the following snippet to trigger this event
  window.VWO = window.VWO || []

  window.VWO.event =
    window.VWO.event ||
    function () {
      window.VWO.push(["event"].concat([].slice.call(arguments)))
    }

  window.VWO.event(name)
}

export const scrollToTop = ({ source }) => {
  setTimeout(() => {
    console.log(`scrolling to top from ${source}`)
    window.scrollTo(0, 0)
  }, 300)
}
