import { SearchStatusUpdate } from "../slices/searchSlice"

export const SearchLoadingMdl = ({ dispatch, getState }) => next => action => {
  next(action)
  const { listings } = getState()
  if (action.meta?.arg?.init === "SEARCH") {
    if (action.type === "listings/ListingsNearBy/pending") {
      dispatch(SearchStatusUpdate({ status: "searching" }))
    }
  }
}

export const SearchMdl = [SearchLoadingMdl]
